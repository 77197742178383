import "../App/App.css";
import React, { useState, useEffect } from "react";
import { useParams, Link } from 'react-router-dom';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import ListingDataService from "../../services/ListingService";
import MessageDataService from "../../services/MessageService";
import MessageForm from "../Message/MessageForm";
import ProfileAuthService from "../../services/profile.auth.service";
import PostcodeDataService from "../../services/PostcodeService";
import FileService from "../../services/FileService";
import Utils from '../../services/utils';
import { categoryData, sectionData, subSectionData, hierarchyPath } from "../../data/hierarchy";
import angle_down from '../../img/angle-down.svg';
import envelope from '../../img/envelope.svg';

function Listing() {
  const { id } = useParams();
  const initialListingState = {
    id: null,
    profileId: null,
    title: '',
    categoryId: '', 
    sectionId: '', 
    subSectionId: '',
    description: '',
    price: '',
    location: '',
    isNew: false,
    published: false,
    image1: '',
    image2: '',
    image3: '',
    image4: '',
    createdAt: "",
    updatedAt: "",
    sellerFirstName: ""
  }
  
  const [currentListing, setCurrentListing] = useState(initialListingState);
  const [currentProfile, setCurrentProfile] = useState({});
  const [currentMessages, setCurrentMessages] = useState([]);
  const [currentImages, setCurrentImages] = useState([]);
  const [openMessages, setOpenMessages] = useState([]);
  const [hasUnreadMessages, setHasUnreadMessages] = useState([]);
  const [itemLocation, setItemLocation] = useState('');

  useEffect(() => {
    const profile = ProfileAuthService.getCurrentProfile();
    console.log("useEffect - profile")
    if (profile) {
      setCurrentProfile(profile);
    }  
  }, []);

  useEffect(() => {
    if (id)
    getListing(id);
  }, [id, currentProfile]);

  useEffect(() => {
    getMessages();
  }, [currentProfile, currentListing]);

  const getListing = id => {
    // *** SHOULD IT REALLY NEED currentProfile? :
    if (currentProfile) { 
      ListingDataService.getWithSeller(id)
        .then(response => { 
          setCurrentListing(response.data[0]);

          let newCurrentImages=[];
          if (response.data[0].image1.length>0) {
            newCurrentImages=[{ 
              original: FileService.uploadsPath+response.data[0].image1,
              thumbnail: FileService.uploadsPath+response.data[0].image1.replace('/', '/150_'),
            }]
          }
          if (response.data[0].image2.length>0) {
            newCurrentImages.push({ 
              original: FileService.uploadsPath+response.data[0].image2,
              thumbnail: FileService.uploadsPath+response.data[0].image2.replace('/', '/150_'),
            })
          }
          if (response.data[0].image3.length>0) {
            newCurrentImages.push({ 
              original: FileService.uploadsPath+response.data[0].image3,
              thumbnail: FileService.uploadsPath+response.data[0].image3.replace('/', '/150_'),
            })
          }
          if (response.data[0].image4.length>0) {
            newCurrentImages.push({ 
              original: FileService.uploadsPath+response.data[0].image4,
              thumbnail: FileService.uploadsPath+response.data[0].image4.replace('/', '/150_'),
            })
          }

          setCurrentImages(newCurrentImages)

          console.log(response.data)
          console.log(JSON.stringify(response.data))
          if (response.data[0].location) { 
            PostcodeDataService.findByImportId(response.data[0].location)
              .then(responseP => {
                console.log(JSON.stringify(responseP.data))
                setItemLocation(PostcodeDataService.formatLocality(responseP.data[0]))
              })
              .catch(e => {
                console.log(e);
              });
            }
        })
        .catch(e => {
          console.log(e);
        });
      }
  };

  const getMessages = (rowId=-1) => {
    if (currentProfile && currentListing) {
      if (currentProfile.id === currentListing.profileId) {
        MessageDataService.getByListing(id)         // Data for Seller View (0 ... n conversations)
          .then(response => {
            let messagesData = response.data
            setCurrentMessages(messagesData)
            console.log("getByListing messagesData: "+JSON.stringify(messagesData));

            let tempHasUnreadMessages = new Array(messagesData.length).fill(false);
            let messagesStartId = 0 
            messagesData.map((currentMessage, index) => { 
              if ((index>0) && (currentMessage.buyerProfileId !== messagesData[index-1].buyerProfileId)) { messagesStartId=index }
              if ((currentMessage.wasRead==0) && (currentMessage.wasFromBuyer==1)) { tempHasUnreadMessages[messagesStartId]=true }  
            })
            setHasUnreadMessages(tempHasUnreadMessages)
            console.log("tempHasUnreadMessages: " + tempHasUnreadMessages)

            console.log("rowId: "+rowId)
            let tempOpenMessages = new Array(messagesData.length).fill(false);
            if (rowId >= 0) {
              tempOpenMessages.map((tempOpenMessage, index) => {
                if (index<=rowId) {tempOpenMessages[index]=openMessages[index];}
                if (index===rowId+1) {tempOpenMessages[index]=true;}
                if (index>(rowId+1)) {tempOpenMessages[index+1]=openMessages[index]}
              })
              console.log("rowId: "+rowId)
            } 
            setOpenMessages(tempOpenMessages);
            console.log("tempOpenMessages: "+JSON.stringify(tempOpenMessages));
          })
          .catch(e => {
            console.log(e);
          });
      } else {
        MessageDataService.getByListingAndBuyer(id, currentProfile.id)    // Data for Buyer View (0 or 1 conversations)
          .then(response => {
            let messagesData = response.data
            setCurrentMessages(messagesData) 
            console.log("getByListingAndBuyer messagesData: "+JSON.stringify(messagesData));

            let tempHasUnreadMessages = new Array(messagesData.length).fill(false);
            messagesData.map((currentMessage, index) => { 
              if ((currentMessage.wasRead==0) && (currentMessage.wasFromBuyer==0)) {tempHasUnreadMessages[0]=true}  
            })
            setHasUnreadMessages(tempHasUnreadMessages)

            console.log("rowId: "+rowId)
            let tempOpenMessages = new Array(messagesData.length).fill(false);
            if (rowId >= 0) {
              tempOpenMessages.map((tempOpenMessage, index) => {
                if (index<=rowId) {tempOpenMessages[index]=openMessages[index];}
                if (index===rowId+1) {tempOpenMessages[index]=true;}
                if (index>(rowId+1)) {tempOpenMessages[index+1]=openMessages[index]}
              })
              console.log("rowId: "+rowId)
            } 
            setOpenMessages(tempOpenMessages);
            console.log("tempOpenMessages: "+JSON.stringify(tempOpenMessages));

            // REMOVE resetOpenMessages
            // if (resetOpenMessages) {
            //   let tempOpenMessages = new Array(messagesData.length).fill(false);
            //   console.log("tempOpenMessages: "+JSON.stringify(tempOpenMessages));
            //   setOpenMessages(tempOpenMessages)
            // } else {
            //   setOpenMessages(new Array(messagesData.length).fill(true));
            // }
            // console.log("openMessages: "+JSON.stringify(openMessages));
            
          })
          .catch(e => {
            console.log(e);
          });        
      }
    }
  }

  const handleClickToggleMessages = (buyerProfileId) => {
    let tempOpenMessages = [...openMessages]
    currentMessages.map((currentMessage, index) => {

      if (currentMessage.buyerProfileId===buyerProfileId) {
        tempOpenMessages[index] = true
        // if (((currentMessage.wasFromBuyer == 1) && (currentMessage.wasRead == 0)) || ((currentMessage.wasFromBuyer == 0) && (currentMessage.wasRead == 0))){
        if ((currentMessage.wasRead == 0) && (((currentMessage.buyerProfileId == currentProfile.id) && (currentMessage.wasFromBuyer == 0)) || ((currentMessage.buyerProfileId != currentProfile.id) && (currentMessage.wasFromBuyer == 1)))) {
          MessageDataService.update(currentMessage.id, {wasRead: 1}) 
          .then(response => {
            // setCurrent  ... state? (maybe like 3 secs later)
          })
          .catch(e => {
            console.log(e);
          });
        }
      }
    setOpenMessages(tempOpenMessages)
    })
  }

  return (
    <>    
      { ((currentListing) && (currentListing.published === 1)) ? (
        <div>
          <div>
            { categoryData.filter((categoryDatum) => { return (categoryDatum.categoryId == currentListing.categoryId) }).map((category) => (
                <span><a href={hierarchyPath(category)}>{category.title}</a> - </span> )
            )}
            { sectionData.filter((sectionDatum) => { return (sectionDatum.sectionId == currentListing.sectionId) }).map((section) => (
                <span>{section.title} - </span> )
            )}
            { subSectionData.filter((subSectionDatum) => { return (subSectionDatum.subSectionId == currentListing.subSectionId) }).map((subSection) => (
                <span>{subSection.title}</span>  )
            )}
          </div>

          <h1>{currentListing.title} {Utils.formatPrice(currentListing.price)}</h1>
          
          <div style={{'display': `flex`}}>
            { (currentListing.image1.length>0) && ( 
              <div style={{'width': `500px`, 'float': `left`}}>              
                <ImageGallery items={currentImages} autoPlay={true} />
              </div>
            )} 

            <div style={{'width': `500px`, 'float': `right`, 'padding-left': `15px`}}>
              { (currentListing.description) && (
                <div style={{whiteSpace: "pre-wrap"}}><b>Description:</b><br/>{currentListing.description}<br/><br/></div>
              )}
              <span><b>Location:</b> {itemLocation}<br/></span>
              <span><b>Condition:</b> { (currentListing.isNew==true) ? `New` : `Used`} </span><br/><br/>
              <span><b>When Listed:</b> {Utils.formatDate(currentListing.createdAt)}</span><br/>
              <span><b>Last Updated:</b> {Utils.formatDate(currentListing.updatedAt)}</span><br/>
              {(currentListing.sellerFirstName) && (
                <><span><b>Name of Seller:</b> {currentListing.sellerFirstName}</span> </>
              )}
              {((currentListing.showMobile) && (currentListing.mobile)) && (
                (currentProfile.id) ? (
                  <span>({currentListing.mobile})</span>
                ) : (
                  <span>(Login to see mobile)</span>
                )
              )}
              <br/><br/>
              {(currentProfile && (currentListing.profileId === currentProfile.id)) && (
                <div> 
                  <Link to={`/listingForm/${id}`}><button className="btn btn-primary">Edit Listing</button></Link>  
                </div>
              )}
            </div>
          </div>


        { (currentMessages) && (
          <> 
            <br/><b>Messages</b><br/><br/>
            <div>
              { (currentMessages) && 
                currentMessages.map((currentMessage, index) => (
                  <div>
                  {((index === 0) || ((index>0) && (currentMessage.buyerProfileId !== currentMessages[index-1].buyerProfileId))) &&  (
                    // Either first message for listing or first from another buyer
                      <>
                        {(index > 0) && <br/> }
                        <span className={`message-tab`} onClick={() => handleClickToggleMessages(currentMessage.buyerProfileId)} >

                          {(hasUnreadMessages[index]===true) && (
                            <img width="20" height="20" alt='New Message(s)' src={envelope}  className={`message-icon`} />
                          )}
                          {(currentProfile && (currentListing.profileId !== currentProfile.id)) ? currentListing.sellerFirstName : currentMessage.buyerFirstName }
                          <img width="20" height="20" alt='Show Message' src={angle_down} />
                      </span>
                      </>
                  )}

                  { (openMessages[index]===true) &&  (
                    <>
                      <div key={index} className={ (currentMessage.wasFromBuyer == 1) ? `message-left` : `message-right`}>
                        { (currentMessage.wasRead == 0) && (
                          (((currentMessage.buyerProfileId == currentProfile.id) && (currentMessage.wasFromBuyer == 0)) || ((currentMessage.buyerProfileId != currentProfile.id) && (currentMessage.wasFromBuyer == 1))) &&  (
                            <img width="20" height="20" alt='New Message(s)' src={envelope} className={`message-icon`} />
                          )
                        )}
                        <span>{currentMessage.content}</span> <span className="message-time small">{Utils.formatDate(currentMessage.updatedAt)}</span>
                        <br/>
                      </div>
                      { ((index==(currentMessages.length-1))  || ( (index<(currentMessages.length-1) ) && (currentMessage.buyerProfileId !== currentMessages[index+1].buyerProfileId)) ) && (
                        <MessageForm listingId={currentMessage.listingId} buyerProfileId={currentMessage.buyerProfileId} wasFromBuyer={!(currentProfile && (currentListing.profileId === currentProfile.id))} onSend={getMessages} rowId={index}/>
                      )}
                    </>
                  )} 
                  </div>
                )
              )}
            </div>
          </>
          )}
        </div>
      ) : (
        <div>Oops! This is not an Active Listing<br/><br/></div>
      )}
      <br/>
      <br/>
    </>
  )

};

export default Listing;
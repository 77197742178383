import React, { useState, useRef, useEffect } from "react";
import { useNavigate, Link } from 'react-router-dom';
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import { isEmail, isMobilePhone } from "validator";

import ProfileAuthService from "../../services/profile.auth.service";
import PostcodeDataService from "../../services/PostcodeService";

const Register = () => {
  let navigate = useNavigate();
  const form = useRef();
  const checkBtn = useRef();

  

  const [lastName, setLastName] = useState("");


  const [mobile, setMobile] = useState("");
  const [marketingCommunications, setMarketingCommunications] = useState(false);

  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState("");
  const [messageEmail, setMessageEmail] = useState("");
  const [messagePassword, setMessagePassword] = useState("");
  const [messageFirstName, setMessageFirstName] = useState("");
  const [messageMobile, setMessageMobile] = useState("");
  const [messagePostcodeImportId, setMessagePostcodeImportId] = useState("");

  const [currentPostcodes, setCurrentPostcodes] = useState([]);
  const [suburb, setSuburb] = useState("");
  const [timerLocation, setTimerLocation] = useState(null);
  const [currentFocusLocation, setCurrentFocusLocation] = useState(-1);
  
  const [postcodeImportId, setPostcodeImportId] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");

  // const [postcodeImportId, setPostcodeImportId] = useState(4478);
  // const [email, setEmail] = useState("japostolellis12@hotmail.com");
  // const [password, setPassword] = useState("12345678");
  // const [firstName, setFirstName] = useState("jacko");

  useEffect(() => {
    console.log("useEffect 1");
    const profile = ProfileAuthService.getCurrentProfile();
    if (profile) {
      navigate('/Profile'); 
    }  
  }, );

  const handleChangeLocation = async (e) => {
    setSuburb(e.target.value);
    if (!e.target.value) { setPostcodeImportId(null); }
    if ((e.target.value).length >= 4) {
      clearTimeout(timerLocation);
      let timeout = setTimeout(() => {
        PostcodeDataService.getPostcodes(e.target.value, setCurrentPostcodes)
      }, 300);
      setTimerLocation(timeout);
    } else {
      setCurrentPostcodes([]);
    }
  }

  const handleClickLocation = (value, clickedPostcodeImportId) => {
    setSuburb(value);
    setCurrentPostcodes([]);
    setCurrentFocusLocation(-1);
    document.getElementById("inputLocation").focus();
    setPostcodeImportId(clickedPostcodeImportId);
    setMessagePostcodeImportId("");
  } 

  const handleKeyDownLocation = event => {
    if (event.keyCode == 40) {
      if (currentPostcodes) {
        if (currentFocusLocation < (currentPostcodes.length - 1)) {
          setCurrentFocusLocation(currentFocusLocation+1); 
        }
      }      
    } else if (event.keyCode == 38) {
      if (currentPostcodes) {
        if (currentFocusLocation >= 0) {
          setCurrentFocusLocation(currentFocusLocation-1); 
        }
      }
    } else if (event.keyCode == 13) {
      if (currentFocusLocation >= 0) {
        setSuburb(PostcodeDataService.formatLocality(currentPostcodes[currentFocusLocation]));
        setPostcodeImportId((currentPostcodes[currentFocusLocation]).importId)
        setCurrentPostcodes([]);
        setCurrentFocusLocation(-1);
        setMessagePostcodeImportId("");
      }      
    }
  } 

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
    setMessageEmail("")
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
    setMessagePassword("")
  };

  const onChangeFirstName = (e) => {
    const firstName = e.target.value;
    setFirstName(firstName);
    setMessageFirstName("")
  };

  const onChangeLastName = (e) => {
    const lastName = e.target.value;
    setLastName(lastName);
  };

  const onChangeMobile = (e) => {
    const mobile = e.target.value;
    setMobile(mobile);
    setMessageMobile("")
  };

  const onChangeMarketingCommunications = (e) => {
    setMarketingCommunications(!marketingCommunications);
  };

  const handleRegister = (e) => {
    e.preventDefault();

    if (!isEmail(email)) {
      setMessageEmail("This is not a valid email.")
      return;
    }

    if (!password) {
      setMessagePassword("Password is a required field.")
      return;
    }
    if (password.length < 8 || password.length > 40) { 
      setMessagePassword("The password must be between 8 and 40 characters.")
      return;
    }

    if (!firstName) {
      setMessageFirstName("First Name is a required field.")
      return;
    }
    
    if((mobile!=="") && (!isMobilePhone(mobile,[`en-AU`]))) {
      setMessageMobile("Sorry. This is not a valid Australian Mobile Number!")
      return;
    }

    if (!postcodeImportId) {
      setMessagePostcodeImportId("Location is a required field.")
      return;
    }

    setMessage("");
    setSuccessful(false);

    form.current.validateAll();

      if (checkBtn.current.context._errors.length === 0) {
        ProfileAuthService.register(firstName, lastName, email, password, mobile, postcodeImportId, marketingCommunications, false, false)
          .then((response) => {
            // setMessage(response.data.message);
            setMessage("Please click on the link sent to your email to activate your account")
            setSuccessful(true);
          },
          (error) => {
            //const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            const outcome = (error.response && error.response.data && error.response.data.outcome)
            if (outcome=="failedCheckDuplicateEmail") {
              setMessageEmail(<>You've already registered with that Email.<br/><Link to='/Login' class='btn btn-link' style={{padding: `6px 0px`}}>Click here</Link> to Login!</>);
            }
            setSuccessful(false);
          }
        );
      }
    // }
  };

  return (
    <div className="wrapper" style={{'align': `center`, width: `420px`}}>
      <h4>Create New Account</h4>
      <hr/>
  
        <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
        />

        <Form onSubmit={handleRegister} ref={form}>
          {!successful && (
            <div>

              <div className="form-group" style={{'display': `flex`, width: `380px`, 'margin-top': `10px`}}>
                <label htmlFor="email" style={{'margin-right': `10px`, 'font-weight': `700`}}>Email&nbsp;*</label>
                <input
                  type="text"
                  className="form-control border border-dark"
                  name="email"
                  value={email}
                  onChange={onChangeEmail}
                />
              </div>

              {messageEmail && (
                <div className="form-group" style={{'margin-top': `10px`}}>
                  <div className={ successful ? "alert alert-success" : "alert alert-danger" } role="alert">
                    {messageEmail}
                  </div>
                </div>
              )}

              <div className="form-group" style={{'display': `flex`, width: `380px`, 'margin-top': `10px`}}>
                <label htmlFor="password" style={{'margin-right': `10px`, 'font-weight': `700`}}>Password&nbsp;*</label>
                <input
                  type="password"
                  className="form-control border border-dark"
                  name="password"
                  value={password}
                  onChange={onChangePassword}
                />
              </div>

              {messagePassword && (
                <div className="form-group" style={{'margin-top': `10px`}}>
                  <div className={ successful ? "alert alert-success" : "alert alert-danger" } role="alert">
                    {messagePassword}
                  </div>
                </div>
              )}
              
              <div className="form-group" style={{'display': `flex`, width: `380px`, 'margin-top': `10px`}}>
                <label htmlFor="firstName" className="form-label" style={{'margin-right': `10px`, 'font-weight': `700`}}>First&nbsp;Name&nbsp;*</label>
                <input
                  type="text"
                  className="form-control border border-dark"
                  name="firstName"
                  value={firstName}
                  onChange={onChangeFirstName}
                />
              </div>

              {messageFirstName && (
                <div className="form-group" style={{'margin-top': `10px`}}>
                  <div className={ successful ? "alert alert-success" : "alert alert-danger" } role="alert">
                    {messageFirstName}
                  </div>
                </div>
              )}
              
              
              <div className="form-group" style={{'display': `flex`, width: `380px`, 'margin-top': `10px`}}>
                <label htmlFor="lastName" style={{'margin-right': `10px`, 'font-weight': `700`}}>Last&nbsp;Name&nbsp;(optional)</label>
                <input
                  type="text"
                  className="form-control border border-dark"
                  name="lastName"
                  value={lastName}
                  onChange={onChangeLastName}
                />
              </div>

              <div className="form-group" style={{'display': `flex`, width: `380px`, 'margin-top': `10px`}}>
                <label htmlFor="mobile" style={{'margin-right': `10px`, 'font-weight': `700`}}>Mobile&nbsp;(optional)</label>
                <input
                  type="text"
                  className="form-control border border-dark"
                  name="mobile"
                  value={mobile}
                  onChange={onChangeMobile}
                />
              </div>

              {messageMobile && (
                <div className="form-group" style={{'margin-top': `10px`}}>
                  <div className={ successful ? "alert alert-success" : "alert alert-danger" } role="alert">
                    {messageMobile}
                  </div>
                </div>
              )}

              <div className="autocomplete"  style={{'display': `flex`, width: `380px`, 'margin-top': `10px`}}>
                <label htmlFor="inputLocation" style={{'margin-right': `10px`, 'font-weight': `700`}}>Location&nbsp;*</label>
                <input
                  id="inputLocation" 
                  type="text" 
                  name="inputLocation"
                  value={suburb}
                  onChange={handleChangeLocation}
                  onKeyDown={handleKeyDownLocation}
                  placeholder="Suburb or postcode ..."
                  autocomplete="off" 
                  className="form-control border border-dark"
                />
                <div id="autocomplete-list" className="autocomplete-items" >
                  {currentPostcodes.map((currentPostcode, index) => (            
                    <div
                      className={(index==currentFocusLocation) && "autocomplete-active"}
                      value={PostcodeDataService.formatLocality(currentPostcode)} 
                      onClick={() => handleClickLocation(PostcodeDataService.formatLocality(currentPostcode), currentPostcode.importId)}
                    >
                      {PostcodeDataService.formatLocality(currentPostcode)}
                    </div>
                    ))}
                </div>
              </div>

              {messagePostcodeImportId && (
                <div className="form-group" style={{'margin-top': `10px`}}>
                  <div className={ successful ? "alert alert-success" : "alert alert-danger" } role="alert">
                    {messagePostcodeImportId}
                  </div>
                </div>
              )}
              
              <div className="form-group" style={{'display': `flex`, width: `380px`, 'margin-top': `10px`}}>
                <label style={{'margin-right': `10px`, 'font-weight': `700`}}>
                  <input
                    type="checkbox"
                    name="marketingCommunications"
                    checked={marketingCommunications}
                    onChange={onChangeMarketingCommunications}
                  />  Marketing&nbsp;Communications
                </label>
              </div>

              <div className="form-group">
                <button className="btn btn-primary btn-block my-3">Sign Up</button>
              </div>
            </div>
          )}



          {message && (
            <div className="form-group">
              <div
                className={ successful ? "alert alert-success" : "alert alert-danger" }
                role="alert"
                style={{'line-height': '1.6'}}
              >
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>

    </div>
  );
};

export default Register;

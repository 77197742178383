import React, { useState, useEffect } from 'react';
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import ProfileAuthService from "../../services/profile.auth.service";
import Register from "../Profile/Register";
import VerifyEmail from "../Profile/VerifyEmail";
import Login from "../Profile/Login";
import Profile from "../Profile/Profile";

import MyListings from "../Listing/MyListings";
import MyMessages from "../Message/MyMessages";

import Home from "../Home/Home";
import ListingList from "../Listing/ListingList";
import SiteMap from "../Home/SiteMap";

import ProductSearch from "../ProductSearch/ProductSearch";
import CreateListing from "../Listing/CreateListing";
import Listing from "../Listing/Listing";

function App() {
  const [currentProfile, setCurrentProfile] = useState(undefined);

  useEffect(() => {
    document.title = 'Pass-A-Buck';
    const profile = ProfileAuthService.getCurrentProfile();

    if (profile) {
      setCurrentProfile(profile);
    }
  }, []);
  
  const handleLogOut = () => {
    ProfileAuthService.logout();
  };

  return (
    <div className="wrapper">

      <Link to={"/"} >
        <h3>PassABuck</h3>
      </Link>
      <nav className="navbar navbar-expand navbar-dark bg-dark justify-content-center">
        <ul className="nav">
          {/* <li className="nav-item">
            <a className="nav-link" href="/">Home</a>
          </li> */}
          <li className="nav-item">
            <a className="nav-link" href="/SiteMap">SiteMap</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/CreateListing">Create Listing</a>
          </li>
          {currentProfile ? (
            <>
              <li className="nav-item">
                <a href="/Profile" className="nav-link nav-link-bold">
                  {currentProfile.firstName}
                </a>
              </li>


              <li className="nav-item">
                <a href="/Listing/MyListings" className="nav-link nav-link-bold">
                  My Listings
                </a>
              </li>

              <li className="nav-item">
                <a href="/Message/MyMessages" className="nav-link nav-link-bold">
                  My Messages
                </a>
              </li>

              <li className="nav-item">
                <a href="/Logout" className="nav-link" onClick={handleLogOut}>
                  Logout
                </a>
              </li>
            </>
          ) : (
            <>
              <li className="nav-item">
                <a href="/Register" className="nav-link">
                  Register
                </a>
              </li>

              <li className="nav-item">
                <a href="/Login" className="nav-link">
                  Login
                </a>
              </li>
            </>
          )}

        </ul>

      </nav>

      <div className="container mt-3">
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/category/:categoryId?/:categoryName?/section/:sectionId?/:sectionName?/subSection/:subSectionId?/:subSectionName?" element={<ListingList />} />
          {/* <Route path="/section/(page)?/:page?/(sort)?/:sort?" component={Section} /> */}
          <Route path="/SiteMap" element={<SiteMap/>} />

          <Route path="/Register" element={<Register />} />
          <Route path="/VerifyEmail/:token" element={<VerifyEmail/>} />
          <Route path="/Profile" element={<Profile/>} />
          <Route path="/Login" element={<Login/>} />
          <Route path="/Logout" element={<Login/>} />

          <Route path="/Listing/MyListings" element={<MyListings/>} />
          <Route path="/Message/MyMessages" element={<MyMessages/>} />
          
          <Route path="/CreateListing" element={<CreateListing />} />
          <Route path="/CreateListing/:id" element={<CreateListing />} />
          <Route path="/CreateListing/product/:productId" element={<CreateListing />} />

          <Route path="/listing/:id" element={<Listing />} />
          <Route path="/productSearch" element={<ProductSearch />} />

        </Routes>
      </div>

    </div>
  );
}

export default App;
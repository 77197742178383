import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import ProfileAuthService from "../../services/profile.auth.service";
import PostcodeDataService from "../../services/PostcodeService";
import { isMobilePhone } from "validator";

const Profile = () => {
  const [currentProfile, setCurrentProfile] = useState({});
  const [firstName, setFirstName] = useState(''); 
  const [lastName, setLastName] = useState('');
  const [mobile, setMobile] = useState('');
  const [marketingCommunications, setMarketingCommunications] = useState(false);
  
  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState("");

  const [messageFirstName, setMessageFirstName] = useState("");
  const [messageMobile, setMessageMobile] = useState("");
  const [messagePostcodeImportId, setMessagePostcodeImportId] = useState("");

  const [currentPostcodes, setCurrentPostcodes] = useState([]);
  const [suburb, setSuburb] = useState("");
  const [timerLocation, setTimerLocation] = useState(null);
  const [currentFocusLocation, setCurrentFocusLocation] = useState(-1);
  const [postcodeImportId, setPostcodeImportId] = useState(null)
  
  const navigate = useNavigate();

  useEffect(() => {
    const profile = ProfileAuthService.getCurrentProfile();
    if (profile) {
      setCurrentProfile(profile);
      setFirstName(profile.firstName);
      setLastName(profile.lastName);
      setMobile(profile.mobile);
      setMarketingCommunications(profile.marketingCommunications);
      setPostcodeImportId(profile.postcodeImportId)
    } else {
      navigate('/Login?refPage=%2FProfile')
    }  
  }, []);

  useEffect(() => {
    if (currentProfile.postcodeImportId)
      getPostcode(postcodeImportId);
  }, [currentProfile, postcodeImportId]);

  const getPostcode = pcImportId => {
    PostcodeDataService.findByImportId(pcImportId)
      .then(response => {
        setSuburb(PostcodeDataService.formatLocality(response.data[0]));
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const onChangeFirstName = (e) => {
    const firstName = e.target.value;
    setFirstName(firstName);
    setMessageFirstName("");
  };

  const onChangeLastName = (e) => {
    const lastName = e.target.value;
    setLastName(lastName);
  };

  const onChangeMobile = (e) => {
    const mobile = e.target.value;
    setMobile(mobile);
    setMessageMobile("");
  };

  const onChangeMarketingCommunications = (e) => {
    setMarketingCommunications(!marketingCommunications);
  };

  const getPostcodes = (value) => {
    // check if "value" consists only of numbers (to search for postcode match)
    if (/^[0-9]+$/.test(value)) {
      PostcodeDataService.findByPostcode(value) // API e.g.: http://localhost:5000/api/postcodes/postcode/2000 
        .then(response => {
          setCurrentPostcodes(response.data);
        })
        .catch(e => {
          console.log(e);
        });
    } else {
      PostcodeDataService.findByLocality(value) // API e.g.: http://localhost:5000/api/postcodes/locality/bal  
        .then(response => {
          setCurrentPostcodes(response.data);
        })
        .catch(e => {
          console.log(e);
        });
    };
  };
 
  const handleChangeLocation = async (e) => {
    setSuburb(e.target.value);
    if (!e.target.value) { setPostcodeImportId(null); }
    if ((e.target.value).length >= 4) {
      clearTimeout(timerLocation);
      let timeout = setTimeout(() => {
        PostcodeDataService.getPostcodes(e.target.value, setCurrentPostcodes)
      }, 300);
      setTimerLocation(timeout);
    } else {
      setCurrentPostcodes([]);
    }
  }

  const handleClickLocation = (value, clickedPostcodeImportId) => {
    setSuburb(value);
    setCurrentPostcodes([]);
    setCurrentFocusLocation(-1);
    document.getElementById("inputLocation").focus();
    setPostcodeImportId(clickedPostcodeImportId);
    setMessagePostcodeImportId("");
  } 

  const handleKeyDownLocation = event => {
    if (event.keyCode == 40) {
      if (currentPostcodes) {
        if (currentFocusLocation < (currentPostcodes.length - 1)) {
          setCurrentFocusLocation(currentFocusLocation+1); 
        }
      }      
    } else if (event.keyCode == 38) {
      if (currentPostcodes) {
        if (currentFocusLocation >= 0) {
          setCurrentFocusLocation(currentFocusLocation-1); 
        }
      }
    } else if (event.keyCode == 13) {
      if (currentFocusLocation >= 0) {
        setSuburb(PostcodeDataService.formatLocality(currentPostcodes[currentFocusLocation]));
        setPostcodeImportId((currentPostcodes[currentFocusLocation]).importId);
        setCurrentPostcodes([]);
        setCurrentFocusLocation(-1);
        setMessagePostcodeImportId("");
      }      
    }
  } 

  const handleProfileUpdate = (e) => {
    e.preventDefault();

    setMessage("");
 
    if (!firstName) {
      setMessageFirstName("First Name is a required field.")
      return;
    }

    if((mobile!=="") && (!isMobilePhone(mobile,[`en-AU`]))) {
      setMessageMobile("Sorry. This is not a valid Australian Mobile Number!")
      return;
    }
    if (!postcodeImportId) {
      setMessagePostcodeImportId("Location is a required field.")
      return;
    }

    setMessage("");
    setSuccessful(false);

    // *** if condition needs to be replaced with validation clearance 
    if (0 === 0) {
      ProfileAuthService.profileUpdate(firstName, lastName, currentProfile.email, mobile, postcodeImportId, marketingCommunications, currentProfile.authByFacebook, currentProfile.wasEmailVerified, currentProfile.id)
      .then(
          (response) => {
            setMessage(response.data.message);
            setSuccessful(true);
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

            setMessage(resMessage);
            setSuccessful(false);
          }
      );
    }
  };

  const handleLogOut = () => {
    ProfileAuthService.logout();
    window.location.reload();
  };

  return (
    <>
      { (currentProfile.id) ? (
      
      <div className="container">
        <div className="jumbotron">
            You are logged in as <strong>{firstName} {lastName}</strong><br/>
            <br/>
            Using <strong>{currentProfile.email}</strong> <br/>
            <br/>
            Please <Link to={"/Login"} onClick={handleLogOut}><strong>logout</strong></Link> if this is not you.  
        </div>
        <br/><br/>
        {message && (
          <div className="form-group"style={{'width': `620px`, 'margin-top': `10px`}}>
            <div
              className={ successful ? "alert alert-success" : "alert alert-danger" }
              role="alert"
            >
              {message}
            </div>
          </div>
        )}

        <form onSubmit={handleProfileUpdate}>
          <div className="wrapper">

            <div className="form-group" style={{'display': `flex`, width: `600px`, 'margin-top': `10px`}}>
              <label htmlFor="firstName" style={{'margin-right': `10px`, 'font-weight': `700`}}>First&nbsp;Name&nbsp;*</label>
              <input
                id="firstName"
                name="firstName"
                type="text"
                value={firstName}
                onChange={onChangeFirstName}
                className="form-control border border-dark" 
              />
            </div>

            { (messageFirstName) && (
              <div className="form-group" style={{'margin-top': `10px`, width: `600px`}}>
                <div className="alert alert-danger" role="alert"> 
                  {messageFirstName}
                </div>
              </div>
            )}

            <div className="form-group" style={{'display': `flex`, width: `600px`, 'margin-top': `10px`}}>
              <label htmlFor="lastName" style={{'margin-right': `10px`, 'font-weight': `700`}}>Last&nbsp;Name&nbsp;(optional)</label>
              <input
                type="text"
                name="lastName"
                value={lastName}
                onChange={onChangeLastName}
                className="form-control border border-dark" 
              />
            </div>

            <div className="form-group" style={{'display': `flex`, width: `600px`, 'margin-top': `10px`}}>
              <label htmlFor="mobile" style={{'margin-right': `10px`, 'font-weight': `700`}}>Mobile&nbsp;(optional)</label>
              <input
                id="mobile"
                name="mobile"
                type="text"
                //"+61"
                value={mobile}
                onChange={onChangeMobile}
                className="form-control border border-dark" 
              />
            </div>

            { (messageMobile) && (
              <div className="form-group" style={{'margin-top': `10px`, width: `600px`}}>
                <div className="alert alert-danger" role="alert"> 
                  {messageMobile}
                </div>
              </div>
            )}

            <div className="form-group" style={{'display': `flex`, width: `600px`, 'margin-top': `10px`}}>
              <input
                type="checkbox"
                name="marketingCommunications"
                checked={marketingCommunications}
                onChange={onChangeMarketingCommunications}
              />
              <label for="marketingCommunications" style={{'margin-right': `10px`, 'font-weight': `700`, 'margin-bottom': `8px`}}>&nbsp;Marketing&nbsp;Communications</label>
            </div>

            <div className="autocomplete form-group"  style={{'display': `flex`, width: `600px`, 'margin-top': `10px`}}>
              <label for="inputLocation" style={{'margin-right': `10px`, 'font-weight': `700`}}>Location&nbsp;*</label>
              <input
                  id="inputLocation" 
                  name="inputLocation"
                  type="text" 
                  value={suburb}
                  onChange={handleChangeLocation}
                  onKeyDown={handleKeyDownLocation}
                  placeholder="Suburb or postcode ..."
                  autocomplete="off"
                  className="form-control border border-dark"
                />
                <div id="autocomplete-list" className="autocomplete-items" >
                  {currentPostcodes.map((currentPostcode, index) => (            
                    <div
                      className={(index==currentFocusLocation) && "autocomplete-active"}
                      value={PostcodeDataService.formatLocality(currentPostcode)} 
                      onClick={() => handleClickLocation(PostcodeDataService.formatLocality(currentPostcode), currentPostcode.importId)}
                    >
                      {PostcodeDataService.formatLocality(currentPostcode)}
                    </div>
                    ))}
                </div>
            </div>

            { (messagePostcodeImportId) && (
              <div className="form-group" style={{'margin-top': `10px`, width: `600px`}}>
                <div className="alert alert-danger" role="alert"> 
                  {messagePostcodeImportId}
                </div>
              </div> 
            )}
            <br/>
            <div className="form-group">
              <button className="btn btn-primary btn-block my-2" style={{'font-weight': `700`, 'width': `150px`}}>Update</button>
            </div>
          </div>
        </form>
      </div>
    ) : (
      <div>
        No Entry for the Anonymous
      </div>
    )}
    </>
  )
}

export default Profile;

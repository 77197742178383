import axios from "../http-common";
import authHeader from "./auth-header";
const API_URL = "/profile.auth/";

const register = (firstName, lastName, email, password, mobile, postcodeImportId, marketingCommunications, authByFacebook, wasEmailVerified) => {
  return axios.post(API_URL + "signup", {
    firstName, 
    lastName,
    email,
    password, 
    mobile, 
    postcodeImportId, 
    marketingCommunications, 
    authByFacebook, 
    wasEmailVerified
  });
};

const verifyEmail = (token) => {
  return axios.post(API_URL + "verifyEmail", {
    token
  });
};

const login = (email, password) => {
  return axios
    .post(API_URL + "signin", {
      email,
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("profile", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("profile");
};

const getCurrentProfile = () => {
  return JSON.parse(localStorage.getItem("profile"));
};

const profileUpdate = (firstName, lastName, email, mobile, postcodeImportId, marketingCommunications, authByFacebook, wasEmailVerified, id) => {
  return axios.post(API_URL + "profileUpdate", { 
    id,
    email, 
    firstName, 
    lastName,
    mobile,
    postcodeImportId,
    marketingCommunications,
    authByFacebook,
    wasEmailVerified
  }, { headers: authHeader() })
 .then((response) => {
    if (response.status == 200) {
      var existing = localStorage.getItem('profile');
      existing = JSON.parse(existing) 
      existing['firstName'] = firstName;
      existing['lastName'] = lastName;
      existing['mobile'] = mobile;
      existing['postcodeImportId'] = postcodeImportId;
      existing['marketingCommunications'] = marketingCommunications;
      existing['authByFacebook'] = authByFacebook;
      existing['wasEmailVerified'] = wasEmailVerified;
      localStorage.setItem('profile', JSON.stringify(existing));
    }
    return response;
   });
};

const ProfileAuthService = {
  register,
  verifyEmail,
  login,
  logout,
  getCurrentProfile,
  profileUpdate
};

export default ProfileAuthService;

const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

class Utils {

  defaultPageSize = 25;

  scrubOutHTML(strIn) {
    let htmlRegexG = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;      
    return strIn.replace(htmlRegexG, '');
  }

  formatPrice(strIn) {
    let strOut = "$"+strIn.replace("$", "")     
    return strOut;
  }

  formatDate(strDate) {

    const today = new Date()
    const workingDate = new Date(strDate)
    let strFormatDate
  
    if ( ((today.getTime() - workingDate.getTime()) < 86400000) && (today.getDate() === workingDate.getDate()) ) {
      strFormatDate = "Today, " 
      if (workingDate.getHours() > 12) { 
        strFormatDate += (workingDate.getHours() - 12) 
      } else { 
        strFormatDate += workingDate.getHours()
      }
      strFormatDate += ":" 
      if (workingDate.getMinutes() < 10) {strFormatDate += "0" }
      strFormatDate += workingDate.getMinutes() 
  
      if (workingDate.getHours() > 11) { 
        strFormatDate += "pm"
      } else { 
        strFormatDate += "am"
      }    
    } else {
      strFormatDate = days[workingDate.getDay()] + ", " 
      strFormatDate += workingDate.getDate() + " " 
      strFormatDate += months[workingDate.getMonth()];
    }
    return strFormatDate 
  }

  formatDate2(strDate) {
    const workingDate = new Date(strDate)
    let strFormatDate = ""
  
    strFormatDate += workingDate.getDate() + " ";
    strFormatDate += months[workingDate.getMonth()] + " ";
    strFormatDate += workingDate.getFullYear(); 
    
    return strFormatDate 
  }

}
  
export default new Utils(); 
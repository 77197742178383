import React, { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import ListingDataService from "../../services/ListingService";
import FileService from "../../services/FileService"; 
import PostcodeDataService from "../../services/PostcodeService";
import Utils from '../../services/utils';

function ListingList() {
  const { categoryId, sectionId, subSectionId } = useParams();
  const [currentListings, setCurrentListings] = useState([]);
  // const [currentCategoryId, setCurrentCategoryId]= useState("0");
  // const [currentSectionId, setCurrentSectionId]= useState("0");
  // const [currentSubSectionId, setCurrentSubSectionId]= useState("0");

  const getListings = () => {
    // setCurrentCategoryId(categoryId)
    // setCurrentSectionId(sectionId);
    // setCurrentSubSectionId(subSectionId);

    console.log("getListings")
    console.log(categoryId)
    console.log(sectionId);
    console.log(subSectionId);
    // ListingDataService.getPublishedWithLocality("10","41","9")
    // ListingDataService.getPublishedWithLocality("0","0","0")
    ListingDataService.getPublishedWithLocality(categoryId,sectionId,subSectionId)
      .then(response => {
        console.log("getListings2")
        console.log(JSON.stringify(response.data))
        setCurrentListings(response.data);

      })
      .catch(e => {
        console.log(e);
      });
  };

  useEffect(() => {
    getListings();
  }, []);
 
  return (
    <div>
      { (currentListings && (currentListings.length>0)) &&
        currentListings.map((currentListing, index) => (
          <div key={index} style={{'display': `flex`, 'padding': `15px 20px`}} className={((index%2)==0) ? "wrapper" : ""}>
            { (currentListing.image1.length>0) && (
                <div style={{'float': `left`, 'paddingRight': `15px`}}>
                  <img alt="" src={`${FileService.uploadsPath}${currentListing.image1.replace('/', '/150_')}`}/>
                </div>
            )}

            <div style={{'float': `right`}}>
              { (currentListing.external) ? (
                <h5><span><a href={currentListing.url} class="btn-link" target="_blank" style={{'text-decoration': `none`, 'font-weight': `900`}}>{currentListing.title}&nbsp;{Utils.formatPrice(currentListing.price)}</a></span></h5> 
              ) : (
                <h5><span><Link class="btn-link" to={`/listing/${currentListing.id}`} style={{'text-decoration': `none`, 'font-weight': `900`}}>{currentListing.title}&nbsp;{Utils.formatPrice(currentListing.price)}</Link></span></h5>
              )}
              
              <span>{currentListing.description}</span><br/>
              
              <span style={{'font-weight': `700`}}>{PostcodeDataService.formatLocality({"locality": currentListing.locality, "state": currentListing.state, "postcode": currentListing.postcode})}</span><br/>
              
              <br/>
            </div>
          </div>
        )
      )}
    </div> 
  );
};

export default ListingList;

// SELECT id AS categoryId, title FROM `categories` 
// Export format: JSON
    
const categoryData = [
    {"categoryId":"1","title":"Books"},
    {"categoryId":"2","title":"Christmas"},
    {"categoryId":"3","title":"Electronics"},
    {"categoryId":"4","title":"Fashion"},
    {"categoryId":"5","title":"Food"},
    {"categoryId":"6","title":"Furniture"},
    {"categoryId":"7","title":"Garden"},
    {"categoryId":"8","title":"Hardware"},
    {"categoryId":"9","title":"Health & Beauty"},
    {"categoryId":"10","title":"Home"},
    {"categoryId":"11","title":"Other"},
    {"categoryId":"12","title":"Outdoor"},
    {"categoryId":"13","title":"Pets"},
    {"categoryId":"14","title":"Sports"},
    {"categoryId":"15","title":"Vehicles"}
    ]
 

// SELECT categoryId, id AS sectionId, title FROM `sections` 
const sectionData = [
    {"categoryId":"1","sectionId":"1","title":"General Books"},
    {"categoryId":"1","sectionId":"2","title":"Kids"},
    {"categoryId":"2","sectionId":"3","title":"Christmas Decorations"},
    {"categoryId":"2","sectionId":"4","title":"Christmas General"},
    {"categoryId":"2","sectionId":"5","title":"Christmas Lights"},
    {"categoryId":"2","sectionId":"6","title":"Christmas Trees"},
    {"categoryId":"2","sectionId":"7","title":"General Christmas"},
    {"categoryId":"3","sectionId":"8","title":"General Electronics"},
    {"categoryId":"3","sectionId":"9","title":"Mobilephones"},
    {"categoryId":"3","sectionId":"10","title":"Routers & Modems"},
    {"categoryId":"3","sectionId":"11","title":"Smart Home"},
    {"categoryId":"3","sectionId":"12","title":"Sound"},
    {"categoryId":"4","sectionId":"13","title":"Baby Clothes"},
    {"categoryId":"4","sectionId":"14","title":"Gardening Clothes"},
    {"categoryId":"4","sectionId":"15","title":"General Fashion"},
    {"categoryId":"4","sectionId":"16","title":"Kids"},
    {"categoryId":"4","sectionId":"17","title":"Mens"},
    {"categoryId":"5","sectionId":"18","title":"General Food"},
    {"categoryId":"6","sectionId":"19","title":"Baby"},
    {"categoryId":"6","sectionId":"20","title":"Kids"},
    {"categoryId":"7","sectionId":"21","title":"Pool"},
    {"categoryId":"8","sectionId":"22","title":"Bathroom"},
    {"categoryId":"8","sectionId":"23","title":"General Hardware"},
    {"categoryId":"8","sectionId":"24","title":"Materials"},
    {"categoryId":"8","sectionId":"25","title":"Paint"},
    {"categoryId":"8","sectionId":"26","title":"Plumbing"},
    {"categoryId":"8","sectionId":"27","title":"Tools"},
    {"categoryId":"9","sectionId":"28","title":"Beauty"},
    {"categoryId":"9","sectionId":"29","title":"Dental"},
    {"categoryId":"9","sectionId":"30","title":"Fitness"},
    {"categoryId":"9","sectionId":"31","title":"Medicines"},
    {"categoryId":"10","sectionId":"32","title":"Appliances"},
    {"categoryId":"10","sectionId":"33","title":"Arts & Crafts"},
    {"categoryId":"10","sectionId":"34","title":"Bathroom"},
    {"categoryId":"10","sectionId":"35","title":"Bedroom"},
    {"categoryId":"10","sectionId":"36","title":"Cleaning"},
    {"categoryId":"10","sectionId":"37","title":"Curtains & Blinds"},
    {"categoryId":"10","sectionId":"38","title":"Decoration"},
    {"categoryId":"10","sectionId":"39","title":"Flooring"},
    {"categoryId":"10","sectionId":"40","title":"Floors"},
    {"categoryId":"10","sectionId":"41","title":"Furniture"},
    {"categoryId":"10","sectionId":"42","title":"General Home"},
    {"categoryId":"10","sectionId":"43","title":"Home Office"},
    {"categoryId":"10","sectionId":"44","title":"Homewares"},
    {"categoryId":"10","sectionId":"45","title":"Kids"},
    {"categoryId":"10","sectionId":"46","title":"Kitchen"},
    {"categoryId":"10","sectionId":"47","title":"Lighting"},
    {"categoryId":"10","sectionId":"48","title":"Office"},
    {"categoryId":"10","sectionId":"49","title":"Smart Home"},
    {"categoryId":"10","sectionId":"50","title":"Storage"},
    {"categoryId":"11","sectionId":"51","title":"Travel"},
    {"categoryId":"12","sectionId":"52","title":"BBQ"},
    {"categoryId":"12","sectionId":"53","title":"Camping"},
    {"categoryId":"12","sectionId":"54","title":"Furniture"},
    {"categoryId":"12","sectionId":"55","title":"Gardening"},
    {"categoryId":"12","sectionId":"56","title":"General Outdoor"},
    {"categoryId":"12","sectionId":"57","title":"Outdoor Furniture"},
    {"categoryId":"12","sectionId":"58","title":"Outdoor General"},
    {"categoryId":"12","sectionId":"59","title":"Pool"},
    {"categoryId":"13","sectionId":"60","title":"General Pets"},
    {"categoryId":"14","sectionId":"61","title":"Bikes"},
    {"categoryId":"14","sectionId":"62","title":"Football"},
    {"categoryId":"15","sectionId":"63","title":"General Vehicles"},
    {"categoryId":"15","sectionId":"64","title":"Motorcycles"}
    ]


// SELECT sectionId, id AS subSectionId, title FROM `subSections`
const subSectionData = [
    {"sectionId":"46","subSectionId":"1","title":"Appliances"},
    {"sectionId":"47","subSectionId":"2","title":"Bathroom"},
    {"sectionId":"41","subSectionId":"3","title":"Bedroom"},
    {"sectionId":"41","subSectionId":"4","title":"Chairs"},
    {"sectionId":"54","subSectionId":"5","title":"Cushions"},
    {"sectionId":"43","subSectionId":"6","title":"Desks & Chairs"},
    {"sectionId":"41","subSectionId":"7","title":"Drawers"},
    {"sectionId":"57","subSectionId":"8","title":"Gazebos"},
    {"sectionId":"41","subSectionId":"9","title":"General Furniture"},
    {"sectionId":"35","subSectionId":"10","title":"Kids"},
    {"sectionId":"41","subSectionId":"11","title":"Kids"},
    {"sectionId":"47","subSectionId":"12","title":"Lamps"},
    {"sectionId":"39","subSectionId":"13","title":"Outdoor"},
    {"sectionId":"47","subSectionId":"14","title":"Outdoor"},
    {"sectionId":"27","subSectionId":"15","title":"Painting Tools"},
    {"sectionId":"55","subSectionId":"16","title":"Pots & Plants"},
    {"sectionId":"11","subSectionId":"17","title":"Security"},
    {"sectionId":"41","subSectionId":"18","title":"Shelving"},
    {"sectionId":"11","subSectionId":"19","title":"Smart Lights"},
    {"sectionId":"48","subSectionId":"20","title":"Smart Lights"},
    {"sectionId":"41","subSectionId":"21","title":"Sofas"},
    {"sectionId":"41","subSectionId":"22","title":"Tables"},
    {"sectionId":"45","subSectionId":"23","title":"Toys"},
    {"sectionId":"41","subSectionId":"24","title":"Trolleys"},
    {"sectionId":"41","subSectionId":"25","title":"TV Stands"},
    {"sectionId":"41","subSectionId":"26","title":"Wardrobes"}
    ]
    

// SELECT id AS retailerId FROM `retailers` 
const retailerData = [
    {"retailerId":"1","title":"Aldi"},
    {"retailerId":"2","title":"Ikea"},
    {"retailerId":"3","title":"Bunnings"},
    {"retailerId":"4","title":"JB Hi-Fi"}
    ]

const defaultCategoryId = "1";
const defaultSectionId = "1";
const defaultSubSectionId = "0";
const defaultRetailerId = "0";

function hierarchyPath(category, section={sectionId:"0",title:"0"}, subSection={subSectionId:"0",title:"0"}) {
    let path = "/category/"+category.categoryId+"/"+category.title.replace(' ', '-').replace(' & ', '-');
    path += "/section/"+section.sectionId+"/"+section.title.replace(' ', '-').replace(' & ', '-');
    path += "/subSection/"+subSection.subSectionId+"/"+subSection.title.replace(' ', '-').replace(' & ', '-');
    // e.g.: /category/10/Home/section/41/Furniture/subSection/22/Appliances"
    return path
  }

module.exports = {
    categoryData,
    sectionData,
    subSectionData,
    retailerData,
    defaultCategoryId,
    defaultSectionId,
    defaultSubSectionId,
    defaultRetailerId,
    hierarchyPath
};
  